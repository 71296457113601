<template>
   <div>
     <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerLearnCloud400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
          <v-card color="transparent" flat >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0" >
                  万学云
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  一站式教学、运营、管理系统 助力教育信息化管理
                </v-card-title>
                <!-- <v-card-actions class="mt-11">
                  <v-btn
                    width="160"
                    height="50"
                    class="ml-2 text-h5 font-weight-regular pa-5 rounded-pill "
                  >
                    <span  class="font-weight-regular banner_span"
                      >立即咨询</span
                    >
                  </v-btn>
                </v-card-actions> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 产品体系 -->
    <v-card class="warp warp2" :flat="true">
      <div class="public-title">
        <p>PRODUCT SYSTEM </p>
        <div>
          <span>基础教育产品体系</span>
          <span>学科类、素质类及XR未来教室 教学+运营+管理全面信息化</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in list" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.h2" />
                <v-card-text class="tip" v-text="v.content" v-html="v.content" />
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 产品方案 -->
    <!--<v-card class="justify-center" :flat="true">-->
    <!--  <div class="public-title ">-->
    <!--    <p>PRODUCT SOLUTIONS</p>-->
    <!--    <div>-->
    <!--      <span>万学云产品方案</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <v-container>-->
    <!--    <v-row justify="center" width='960' class="solutions_row">-->
    <!--      <v-col cols="6" v-for="n in solutions" :key="n.id">-->
    <!--        <v-card flat >-->
    <!--          <div class="img_title " justify="center">-->
    <!--            <h2>{{n.title}}</h2>-->
    <!--            <v-img :src="n.src" class="mt-8"/>-->
    <!--            <div>-->
    <!--              <div style="float:left;padding:0 21px 0 34px;text-align: left;line-height:24px" v-html='n.content'>{{n.content}}</div>-->
    <!--              <div style="padding-right:16px"><v-btn class="look_btn" @click="goCaseDetail(n.anli)">查看详情</v-btn></div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </v-container>-->
    <!--</v-card>-->
    
    <!-- 优势 -->
    <v-card class="justify-center" :flat="true" style="margin-top:70px;margin-bottom: 38px;">
      <div class="public-title">
        <p>OUR ADVANTAGE</p>
        <div>
          <span>我们的优势</span>
          <span>自主研发技术引擎及数十年行业深耕，打造行业专业的信息化教研平台</span>
        </div>
      </div>
      <v-container class="max-width-1200 mt-11">
        <v-row justify="center" >  
          <v-col cols="4" v-for="n in advantage" :key="n.id">
            <v-card flat class="advantage_card">
              <div class="img_title" justify="center">
                <v-img :src="n.src" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                  <div class="white--text font-size-20 pl-6 pr-6 pb-3 font-weight-bold" style="text-align:left;">{{n.title}}</div>
                  <div class="white--text subtitle-1 pl-6 pr-6 pb-5" style="text-align:left;line-height: 20px;height:55px" v-html='n.content'>{{n.content}}</div>
                </v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
   </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "LearnCloud",
  data(){
    return{
      model: 0,
      tab:null,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      list:[
        {title:"VR小学科学",h2:'沉浸式+强交互情景教学 参与感更强',img:'https://h5.ophyer.cn/official_website/other/learn1-1.jpg',content:'• 涵盖天文地理生物中小学系列72节<br/> • 强大的交互性，让学生更好地参与知识情景 <br/> • 沉浸式情景教学，营造真实可触的类现实世界'},
        {title:"线上研学课程",h2:'文化、历史、军事研学基地',img:'https://h5.ophyer.cn/official_website/other/learn2-1.jpg',content:'• 包含名人故居，历史文物、建筑，纪念展馆等220个基地<br/>• 将教研内容与全景结合，互动式体验，提升教研效果'},
        {title:"中小学课后服务平台",h2:'一站式校内课后服务解决方案',img:'https://h5.ophyer.cn/official_website/other/learn3-1.jpg',content:'• 通过新技术将续集与现实技术完美集合，促进教学内容生动性<br/>• 以游戏化细微贯穿，提升学习兴趣，达到全脑记忆'},
        {title:"爱国主义教育",h2:'思想政治党建系列全景课程',img:'https://h5.ophyer.cn/official_website/other/learn4-1.png',content:'• 包括长征、数字展馆及强国之路多种系列课程<br/>• 强交互体验，在全景中感受国家、民族的复兴之路<br/>• 支持微定制、全新定制 '},
        {title:"中小学智慧教室",h2:'虚拟现实智慧，创新教室建设',img:'https://h5.ophyer.cn/official_website/other/learn5-1.png',content:'• 高级智慧教室，100+课程任选<br/>• VR眼镜、3D裸眼屏、中控电脑、中控管理系统一站式服务<br/>• 以第一人称置身于类真实的情景，深化课程体验'},
        
      ],
      solutions: [
        { title: 'VR播控系统', src: 'https://h5.ophyer.cn/official_website/other/learnimg31.jpg', content:'用于教学/实验、拓展体验，方案灵活，<br/>易落地',anli:'bokongxitong'},
        { title: '全景运营创业培训', src: 'https://h5.ophyer.cn/official_website/other/learnimg32.jpg', content:'设备使用教学+全景制作工具培训，享受<br/>平台派单，创业如此简单',anli:'chuangyepeixun'},
      ],
      advantage:[
        { title: '国家政策扶持', src: 'https://h5.ophyer.cn/official_website/other/learnYS1.png', content:'匹配教育部对虚拟现实专业的建设要求'},
        { title: '大平台技术支撑', src: 'https://h5.ophyer.cn/official_website/other/learnYS2.png', content:'虚拟现实应用技术引领者，打造VR<br/>虚拟现实教学平台'},
        { title: '教学资源丰富', src: 'https://h5.ophyer.cn/official_website/other/learnYS3.png', content:'对接主流教育行业，拥有各专业丰富的<br/>教学资源'},
        { title: '完善的课程体系', src: 'https://h5.ophyer.cn/official_website/other/learnYS4.png', content:'完善的课程体系，打造一站式智慧<br/>教育平台'},
        { title: '满足多场景教学', src: 'https://h5.ophyer.cn/official_website/other/learnYS5.png', content:'满足教、学、练、测、评的需求，全场景<br/>教学，提升学习效率'},
        { title: '专业拓展', src: 'https://h5.ophyer.cn/official_website/other/learnYS6.png', content:'依托虚拟现实技术，支持各专业虚拟仿真<br/>训练室的建设'},
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .v-card__subtitle, .v-card__text ,.text-subtitle-1{
  line-height: 26px !important;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF !important;
  // padding-top: 10%;
}
.scence_text{
  color: #FFFFFF !important;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
::v-deep.solutions_row{
  width: 960px;
  margin: 0 auto;
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}

.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 20px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      width:400px ;
      height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
::v-deep .look_btn{
  width: 120px;
  height: 40px !important;
  background: #0568FD !important;
  border-radius: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 13px;
}
::v-deep .v-carousel__controls{
  display: none !important;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>